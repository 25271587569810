
import {
  payPlanChangeRequestMapState,
  payPlanChangeRequestMapActions,
  payPlanChangeRequestMapMutations,
  payPlanChangeRequestMapGetters
} from "@/store/modules/payplanchangerequest";
import { companiesMapGetters } from "@/store/modules/companies";
import { omit, get } from "lodash";
import { StorageFormStatus } from "@/helpers/storageFormBaseInterface";
import { authMapGetters } from "@/store/modules/auth";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

import * as types from "@/store/mutation-types";
import { objectDifference, toTitleCaseFromCamelCase } from "@/helpers";
import { object } from "dot-object";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";

interface IPayPlan {
  errorMessage: string;
  successMessage: string;
  resourceNotFound: boolean;
  didInit: boolean;
  payPlanChangeRequestData: any;
  pageDataInitialized: boolean;
}

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "PayPlanChangeRequestDetailsView",
  mixins: [ActivitiesMixin],
  data(): IPayPlan {
    return {
      errorMessage: "",
      successMessage: "",
      resourceNotFound: false,
      didInit: false,
      payPlanChangeRequestData: {},
      pageDataInitialized: false
    };
  },
  components: {
    // DataView
    ViewChangeRequestInsurerDetails: () =>
      import("@/components/ChangeRequests/ViewChangeRequestInsurerDetails.vue"),
    ViewChangeRequestPolicyDetails: () =>
      import("@/components/ChangeRequests/ViewChangeRequestPolicyDetails.vue"),
    ChangeRequestStatus: () =>
      import("@/components/ChangeRequests/ChangeRequestStatus.vue"),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    payPlanChangeRequestId(): string {
      return this.$route.params.payplanchangerequest_id;
    },
    ...payPlanChangeRequestMapState(["makingApiRequest", "editing"]),
    ...payPlanChangeRequestMapGetters(["getPayPlanChangeRequestById"]),
    original(): any {
      return this.getPayPlanChangeRequestById(this.payPlanChangeRequestId);
    },
    showApprovedButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "approved" &&
        (this.editing as any).status !== "rejected"
      );
    },
    showDeclineButton(): boolean {
      return !!(
        this.editing &&
        (this.editing as any).status !== "rejected" &&
        (this.editing as any).status !== "approved"
      );
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },
    updatedFields(): any {
      const toIgnore = ["data.agentInfo"];
      return omit(
        this.editing && this.original
          ? objectDifference(this.editing, this.original)
          : {},
        toIgnore
      );
    },
    primaryToolbar(): any {
      if (this.editing) {
        return {
          text: "Save",
          key: "save",
          loading: this.makingApiRequest,
          disabled: Object.keys(this.updatedFields).length === 0
        };
      }
      return undefined;
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
    } | null {
      if (get(this.editing, "deleted") || get(this.editing, "isLegacy")) {
        return null;
      }
      let options = [
        {
          title: "Print",
          command: "print"
        }
      ];
      let secondaryMenu = {
        text: "Actions",
        key: "actions",
        subItems: options
      };
      if (
        get(this.original, "status") === "Not Submitted" ||
        (get(this.getCurrentUser, "role") === "admin" &&
          get(this.original, "status") !== "Received" &&
          get(this.original, "status") !== "Submitted" &&
          get(this.original, "status") !== "Issued" &&
          !get(this.original, "printed"))
      ) {
        options.push({
          title: "Edit",
          command: "edit"
        });
      }
      return secondaryMenu;
    },
    queryOverride(): any {
      return {
        "data.payPlanChangeRequestId": this.editing?._id,
        activityType: "PayPlanChangeRequestActivity"
      };
    }
  },
  created() {
    this.initializePageData();
  },
  destroyed() {},
  methods: {
    ...payPlanChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    ...payPlanChangeRequestMapActions([
      "getPayPlanChangeRequest",
      "updatePayPlanChangeRequest"
    ]),
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    },
    // function to get the property of the object
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },
    changeStatus(action: StorageFormStatus): void {
      this.editField({
        key: "status",
        value: action
      });
    },
    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "save":
          try {
            this.Submit();
          } catch (error) {
            this.$appNotifyError(error.message);
            this.$bugSnagClient.notify(error);
          }
          break;
        case "edit":
          this.$router
            .push(`/payplanchangerequest/edit/${this.editing?._id}`)
            .catch(() => {});
          break;
        case "print":
          this.printCallback();
          break;
        default:
          break;
      }
    },
    async Submit() {
      if (this.editing) {
        const { status } = this.editing;
        await this.updatePayPlanChangeRequest({
          id: this.payPlanChangeRequestId,
          update: {
            status
          }
        });
        this.$appNotifySuccess("Status changed");
      }
    },
    async initializePageData() {
      try {
        await this.getPayPlanChangeRequest(this.payPlanChangeRequestId);
        const { data, ...rootFields } = this.editing as any;
        this.payPlanChangeRequestData = {
          ...rootFields,
          ...data
        };
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },
    changeValueToLabel(value: string): string {
      let str = "";
      switch (value) {
        case "coverageElsewhere":
          str = "Coverage Elsewhere";
          break;
        case "propertySold":
          str = "Property Sold";
          break;
        case "other":
          str = "Other";
          break;
        default:
          break;
      }
      return str;
    },
    //function to change a string from camelCase to Title Case separated by a space
    toTitleCaseFromCamelCase(str: string): string {
      return toTitleCaseFromCamelCase(str);
    },
    toTitleCaseFromKebabCase(value: string): string {
      let str = "";
      switch (value) {
        case "annual":
          str = "Annual";
          break;
        case "semiAnnual":
          str = "Semi Annual";
          break;
        case "4pay":
          str = "4 Pay";
          break;
        case "9pay":
          str = "9 Pay";
          break;
        default:
          break;
      }
      return str;
    },
    async printCallback() {
      const policyPrefix =
        this.editing &&
        this.editing.data &&
        this.editing.data.policyInfo &&
        this.editing.data.policyInfo.policyPrefix
          ? this.editing.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: this.editing?._id,
        policyPrefix,
        printAction: "payplanchangerequest/printPayPlanChangeRequest"
      });
    }
  }
});
